export const userAccess = {
    computed: {
      entities () {
        // entities subject to change as prodcut catalog matures
        return ['product']
      },
      adminRoles () {
        return ['product-catalog.admin', 'client-admin', 'jbg-admin', 'item.admin']
      },
      adminEntities () {
        return [...this.entities, 'key', 'key_value']
      },
      user () {
        return this.$auth.user
      },
      tenant () {
        return this.$auth.tenant
      },
      userRoles () {
        return this.$auth.userRoles || []
      },
      userPermissions () {
        return this.$auth.permissions || []
      },
      userRelatedParties () {
        return this.$store.getters.userRelatedParties
      },
      userStoreParties () {
        return this.getUserPartiesByType('STORE')
      },
      userStorePartyIds () {
        return this.userStoreParties.flatMap(s => s?.id || [])
      },
      allStores () {
        return this.$store.getters.stores
      },
      userStores () {
        if (!this.isAdmin) {
          if (this.userStorePartyIds.length > 0) {
            return this.allStores.filter(s => this.userStorePartyIds.includes(s?.id))
          }
          return []
        }
        return this.allStores
      },
      sources () {
        return this.$store.getters.sources
      },
      hasPortalAccess () {
        const readScopes = this.entities.map(entity => `${entity}:read`)
        return readScopes.every(scope => this.userPermissions.includes(scope))
      },
      hasWriteAccess () {
        return this.writePermissions.every(scope => this.userPermissions.includes(scope))
      },
      writePermissions () {
        const actions = ['create', 'read', 'update']
        return this.entities.flatMap(entity => {
          return actions.map(action => `${entity}:${action}`)
        })
      },
      isAdmin () {
        return this.adminRoles.some(role => this.userRoles.includes(role))
      },
      isRetailer () {
        return this.userRoles.includes('jbg-retailer')
      },
      limitAccessByRelatedSources () {
        return !this.isAdmin && this.userPermissions.includes('item:related_sources')
      },
      adminPermissions () {
        return this.$auth.buildScopes(this.adminEntities)
      },
    },
    methods: {
      hasScope (scope) {
        return this.userPermissions.includes(scope)
      },
      getUserPartiesByType (partyType) {
        return this.userRelatedParties.filter(party => {
          return party && party.party_type_constant === partyType
        })
      }
    }
  }