<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card tile flat color="grey lighten-3">
      <v-toolbar class="ml-4 pt-4" flat color="grey lighten-3" style="cursor: default;">
        <v-icon left>mdi-clipboard-text</v-icon>
        <v-toolbar-title class="title">
          Order Entry
        </v-toolbar-title>
        <v-col lg="4" style="max-width: 430px" class="text-truncate ml-4">
          <v-autocomplete
            v-model="storeId"
            label="Store"
            :items.sync="stores"
            :loading="loading"
            :disabled="loading"
            item-text="name"
            item-value="id"
            outlined
            dense
            required
            :rules="[v => !!v || 'Store is required']"
            background-color="input"
            hide-details="auto"
            prepend-inner-icon="mdi-store-outline">
          </v-autocomplete>
        </v-col>
        <v-col lg="4" style="max-width: 430px" class="d-none d-sm-flex text-truncate">
          <v-text-field
            v-model="note"
            label="Customer Invoice Note (Optional)"
            dense
            validate-on-blur
            background-color="input"
            outlined
            maxlength="14"
            hide-details="auto"
            @keyup.stop="handleNoteInput(note)">
          </v-text-field>
        </v-col>
      </v-toolbar>
      <v-container fluid class="px-4 mb-4">
        <!-- row below only visible on smallest screen size -->
        <v-row dense align="center" class="d-flex d-sm-none px-4 mt-2">
          <v-col class="text-truncate">
            <v-text-field
              v-model="note"
              label="Customer Invoice Note (Optional)"
              dense
              validate-on-blur
              background-color="input"
              outlined
              maxlength="14"
              hide-details="auto"
              @keyup.stop="handleNoteInput(note)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-4">
          <v-col cols="auto" sm="3" md="2">
            <v-text-field
              v-model="itemId"
              label="Item ID"
              :disabled="loading"
              dense
              required
              :rules="[v => !!v || 'Item ID is required']"
              background-color="input"
              validate-on-blur
              outlined
              hide-details="auto">
            </v-text-field>
          </v-col>
          <v-col cols="auto" sm="2" md="1">
            <v-text-field
              label="Qty"
              v-model="item.quantity"
              type="number"
              min="1"
              background-color="input"
              outlined
              dense
              hide-details="auto"
              :disabled="loading"
              @input="qtyChange()">
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              style="margin-top: 2px"
              color="primary"
              :disabled="disableLookup || loading"
              @click="lookupItem">
              <v-icon left>mdi-magnify</v-icon>
              Lookup
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              style="margin-top: 2px"
              color="success"
              :disabled="disableLookup || !item.uuid || existingItem || loading"
              @click="addItem(item)">
              Add Item
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="px-4 mt-4 d-none d-sm-flex"> <!-- to do: expansion panel instead of hiding if xs display? -->
          <v-col cols="auto" md="3" sm="4">
            <v-text-field
              v-model="item.description"
              label="Description"
              disabled
              readonly
              dense>
            </v-text-field>
          </v-col>
          <v-col cols="auto" sm="2">
            <v-text-field
              v-model="item.upc"
              label="UPC"
              disabled
              readonly
              dense>
            </v-text-field>
          </v-col>
          <v-col cols="auto" md="1" sm="2">
            <v-text-field
              v-model="item.qty_per_case"
              label="Pack"
              disabled
              readonly
              dense>
            </v-text-field>
          </v-col>
          <v-col cols="auto" md="1" sm="2">
            <v-text-field
              v-model="item.pack_size"
              label="Size"
              disabled
              readonly
              dense>
            </v-text-field>
          </v-col>
          <v-col cols="auto" md="1" sm="2">
            <v-text-field
              v-model="itemCost"
              label="Cost"
              disabled
              readonly
              dense>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid style="border-top: 1px solid #ddd; background-color: #fff">
        <v-row dense align="center">
          <v-col class="ml-2 d-none d-sm-flex">
            <v-toolbar-title>Order Items</v-toolbar-title>
          </v-col>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <v-col cols="auto" class="pr-4">
            <p class="font-weight-medium text-no-wrap text-h6 mb-0">
              Total: ${{ totalCost }}
            </p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="success"
              :loading="loading"
              :disabled="orderItems.length === 0 || loading"
              @click="confirmSubmit = true">
              Submit Order
            </v-btn>
            <v-btn
              class="white--text ml-2"
              :disabled="!order.id || loading"
              fab 
              x-small 
              elevation="2"
              color="error"
              @click.stop="confirmDelete = true">
              <v-icon dense>mdi-trash-can</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card>
      <OrderItemsTable :items="orderItems" @updateItem="updateItem" @removeItem="removeItem"/>
    </v-card>
    <ConfirmDialog
      v-if="confirmSubmit"
      v-model="confirmSubmit"
      title="Submit Item Order"
      text='Change order status to "submitted"? Changes may no longer be made to the item order after submission.'
      @cancel="cancelSubmit"
      @confirm="submitOrder">
    </ConfirmDialog>
    <ConfirmDialog
      v-if="confirmDelete"
      v-model="confirmDelete"
      title="Clear Item Order"
      text='Clear the current order? This will clear all items.'
      @cancel="cancelDelete"
      @confirm="clearOrder">
    </ConfirmDialog>
  </v-container>
</template>
<script>
// API
import Items from '@/api/item'
import ProductOrder from '@/api/product-order'
// Components
import OrderItemsTable from '@/components/items/OrderItemsTable.vue'
const ConfirmDialog = () => import('@/components/shared/ConfirmDialog.vue')
// Mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { debounce } from 'lodash'
export default {
  name: 'OrderEntryView',
  data() {
    return {
      order: {},
      orderItems: [],
      hideDetails: false,
      loading: true,
      itemId: null,
      item: {
        description: '',
        quantity: 1,
        pack_size: '',
        cost: '',
        upc: '',
        qty_per_case: '',
      },
      note: null,
      storeId: null,
      confirmSubmit: false,
      confirmDelete: false
    }
  },
  mixins: [userAccess, displayAlert, utils],
  watch: {
    sourceId: {
      handler(newValue) {
        if (newValue) this.orderItems = []
      }
    },
    stores: {
      handler() {
        if (!this.storeId) this.storeId = this.stores[0].id
      }
    },
    storeId: {
      async handler(newValue) {
        this.order = {}
        this.orderItems = []
        this.note = null
        this.resetFields()
        this.loading = true
        await this.getProductOrder()
        sessionStorage.setItem('store_id', newValue)
        this.loading = false
      }
    }
  },
  components: { OrderItemsTable, ConfirmDialog },
  async created() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('getUserRelatedParties'),
      this.$store.dispatch('getProductOrderTypes'),
      this.$store.dispatch('getStores')
    ]).catch(err => {
      this.loading = false
      this.handleError(err)
    })
    if (this.stores.length > 0) {
      if (sessionStorage.store_id) {
        this.storeId = sessionStorage.store_id
      } else {
        this.storeId = this.stores[0].id
      }
    }
    this.loading = false
  },
  computed: {
    existingItem() {
      if (this.orderItems.length > 0 && this.item.item_id) {
        return this.orderItems.some(item => item.item_id === this.item.item_id)
      }
      return false
    },
    disableLookup() {
      return !this.sourceId || !this.itemId
    },
    stores() {
      if (this.userStores.length > 0) {
        return this.sortByKey(this.userStores, 'name')
      }
      return []
    },
    selectedStore() {
      return this.stores.find(store => this.storeId === store?.id)
    },
    sourceId() {
      return this.selectedStore?.dc_source
    },
    selectedSources() {
      let sources = [this.sourceId]
      const alt_dc_sources = this.selectedStore.alt_dc_sources
      if (alt_dc_sources.length > 0) {
        sources = [...new Set(sources.concat(alt_dc_sources))]
      }
      return sources
    },
    productOrderTypes() {
      return this.$store.state.productOrderTypes
    },
    itemProductOrderTypeId() {
      if (this.productOrderTypes && this.productOrderTypes.length) {
        return this.productOrderTypes.find(kv => kv.constant === "ITEM")?.id
      } else {
        return null
      }
    },
    itemCost() {
      return this.item.cost ? '$' + this.$config.formatCurrency(this.item.cost) : ''
    },
    totalCost() {
      let finalTotal = 0
      if (this.orderItems?.length > 0) {
        finalTotal = this.orderItems.reduce((total, item) => {
          return total + (item.cost * item.quantity)
        }, 0)
      }
      return this.$config.formatCurrency(finalTotal)
    }
  },
  methods: {
    handleNoteInput: debounce(async function (note) {
      this.loading = true
      try {
        if (!this.order?.id) {
          await this.createProductOrder()
        } else {
          const params = {
            party_id: this.storeId,
            product_event_id: null,
            product_order_type_id: this.itemProductOrderTypeId,
            submitted: false,
            note: note
          }
          await ProductOrder.updateProductOrder(params, this.order.id, 'ITEM')
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    }, 400),
    async lookupItem() {
      this.loading = true
      try {
        const params = { item_id: this.itemId, sources: this.selectedSources }
        if (this.selectedStore.catalog_name) {
          params.catalog_name = this.selectedStore.catalog_name
        }
        const { data } = await Items.searchItems(params, 0, 50, true)
        if (data.length > 0) {
          this.item = { ...this.item, ...data[0] }
          if (this.existingItem) {
            this.emitAlert(true, 'warning', 'Item already in order')
          }
        } else {
          this.emitAlert(true, 'warning', 'Item not found')
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async getProductOrder() {
      try {
        const params = { product_order_type_id: this.itemProductOrderTypeId }
        const { data } = await ProductOrder.getProductOrder(this.storeId, params)
        if (data) {
          this.order = data
          this.note = data.note ? data.note : ''
          await this.getProductOrderItems()
        }
      } catch (e) {
        this.handleError(e)
      }
    },
    async createProductOrder() {
      try {
        const orderPayload = {
          party_id: this.storeId,
          product_event_id: null,
          product_order_type_id: this.itemProductOrderTypeId,
          submitted: false,
          note: this.note
        }
        const productOrderRes = await ProductOrder.createProductOrder(orderPayload)
        this.order.id = productOrderRes?.data?.uuid
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      }
    },
    async getProductOrderItems() {
      try {
        const params = { product_order_type: 'ITEM', catalog_name: this.selectedStore.catalog_name, offset: 0, limit: 500 }
        const { data } = await ProductOrder.getProductOrderItems(params, this.order.id)
        const sorted = data.sort((a, b) => Date.parse(a.audit.created_on) - Date.parse(b.audit.created_on))
        this.orderItems = sorted.map(product => this.setProductPrices(product, this.storePriceKey))
      } catch (err) {
        this.handleError(err)
      }
    },
    async addItem(item) {
      this.loading = true
      try {
        if (!this.order?.id) {
          await this.createProductOrder()
        }
        const productOrderItem = { 
          product_order_id: this.order.id, 
          source: item.source, 
          item_id: item.item_id,
          upc: item.upc, 
          quantity: Number(item.quantity)
        }
        await ProductOrder.createProductOrderItem(productOrderItem)
        await this.getProductOrder()
        this.resetFields()
      } catch (e) {
        this.handleError(e)
      }
      this.loading = false
    },
    async removeItem(item) {
      try {
        this.loading = true
        await ProductOrder.deleteProductOrderItem(item.id)
        await this.getProductOrder()
        if (this.order?.id && this.orderItems.length == 0) {
          await this.clearOrder()
        }
        this.loading = false
      } catch (e) {
        this.handleError(e)
      }
    },
    async updateItem(item) {
      this.loading = true
      try {
        const productOrderItem = { 
          product_order_id: this.order.id, 
          source: item.source, 
          item_id: item.item_id,
          upc: item.upc, 
          quantity: Number(item.quantity)
        }
        await ProductOrder.updateProductOrderItem(item.id, productOrderItem)
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      }
      this.loading = false
    },
    async submitOrder() {
      this.confirmSubmit = false
      this.loading = true
      try {
        const params = {
          party_id: this.storeId,
          product_event_id: null, 
          product_order_type_id: this.itemProductOrderTypeId,
          submitted: true,
          processed: false,
          note: this.note
        }
        await ProductOrder.updateProductOrder(params, this.order.id, 'ITEM')
        this.resetFields()
        this.orderItems = []
        this.order = {}
        this.emitAlert(true, 'success', 'Order Submitted')
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async clearOrder() {
      this.confirmDelete = false
      this.loading = true
      try {
        if (this.orderItems.length > 0) {
          const promises = this.orderItems.map((productOrderItem) => {
            return ProductOrder.deleteProductOrderItem(productOrderItem.id)
          })
          const { rejected } = await this.getAllSettled(promises)
          if (rejected?.length > 0) throw rejected
        }
        await ProductOrder.deleteProductOrder(this.order.id)
        this.resetFields()
        this.orderItems = []
        this.order = {}
        this.note = null
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    cancelSubmit() {
      this.confirmSubmit = false
    },
    cancelDelete() {
      this.confirmDelete = false
    },
    qtyChange() {
      if (this.item.quantity < 0) {
        this.item.quantity = 0
      }
    },
    increaseQty() {
      this.item.quantity += 1
    },
    decreaseQty() {
      this.item.quantity -= 1
    },
    resetFields() {
      this.itemId = null
      this.resetItem()
    },
    resetItem() {
      this.item = {
        description: '',
        quantity: 1,
        pack_size: '',
        cost: '',
        qty_per_case: '',
        upc: ''
      }
    }
  }
}
</script>
<style scoped>
.v-application--is-ltr .v-data-footer__pagination {
  margin-left: auto;
}
.qty-controls {
  display: grid; 
  width: 100%; 
  max-width: 100px; 
  grid-template-columns: auto 40px auto;
}
</style>