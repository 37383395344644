var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "grey lighten-3", attrs: { flat: "" } },
        [
          _c("v-icon", { staticClass: "mr-2" }, [
            _vm._v("mdi-package-variant-closed"),
          ]),
          _c("v-toolbar-title", [_vm._v("Order History")]),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { inset: "", vertical: "" },
          }),
          _c(
            "v-col",
            { staticStyle: { "max-width": "430px" }, attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  dense: "",
                  items: _vm.stores,
                  loading: _vm.loading,
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "",
                  clearable: _vm.isAdmin,
                  "background-color": "white",
                  label: "Store",
                  "prepend-inner-icon": "mdi-store-outline",
                },
                on: { change: _vm.getOrders },
                model: {
                  value: _vm.storeId,
                  callback: function ($$v) {
                    _vm.storeId = $$v
                  },
                  expression: "storeId",
                },
              }),
            ],
            1
          ),
          _c("ProductOrderFilter", {
            attrs: { loading: _vm.loading },
            on: { orderFilterInput: _vm.handleFilterInput },
            model: {
              value: _vm.searchParams,
              callback: function ($$v) {
                _vm.searchParams = $$v
              },
              expression: "searchParams",
            },
          }),
          _c("v-spacer"),
          _vm.isAdmin
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-4 white--text",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.eventOrderExportModal = true
                    },
                  },
                },
                [
                  _vm._v(" Export Summary "),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-download"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-4",
                              attrs: {
                                to: "/products",
                                small: "",
                                fab: "",
                                color: "main",
                                rounded: "",
                                text: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-cart")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Back to Products")])]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          headers: _vm.headers,
          items: _vm.productOrders,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "no-data-text": _vm.searchParams.submitted
            ? "No submitted orders to show"
            : "No orders to show",
          "hide-default-footer": "",
          "items-per-page": _vm.pageSize,
          page: _vm.page,
          "item-key": "id",
          "fixed-header": "",
          dense: "",
        },
        on: {
          "update:items": function ($event) {
            _vm.productOrders = $event
          },
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.product_order_number`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "text-no-wrap" }, [
                    _vm._v(
                      " " + _vm._s(item.product_order_number || "-") + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.submitted`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        small: "",
                        dark: "",
                        color: _vm.getStatusColor(item.submitted),
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            margin: "auto",
                            width: "72px",
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getSubmittedText(item.submitted)) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.audit.created_on`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$config.formatTimestamp(item.audit.created_on)
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.submitted_on`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.submitted_on
                            ? _vm.$config.formatTimestamp(item.submitted_on)
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.order_items`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showOrderView(item)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-view-list")])],
                    1
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("PagingFooter", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      pageSize: _vm.pageSize,
                      page: _vm.page,
                      disablePrevious: _vm.disablePrevious,
                      disableNext: _vm.disableNext,
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      previous: _vm.previous,
                      next: _vm.next,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "300" } },
        [
          _vm.orderModal
            ? _c("OrderView", {
                attrs: {
                  selectedStore: _vm.selectedStore,
                  selectedProductOrder: _vm.selectedProductOrder,
                  storePriceKey: _vm.storePriceKey,
                },
                on: { closeOrderView: _vm.closeOrderView },
                model: {
                  value: _vm.orderModal,
                  callback: function ($$v) {
                    _vm.orderModal = $$v
                  },
                  expression: "orderModal",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.eventOrderExportModal
        ? _c("EventOrderExportView", {
            on: {
              closeEventOrderExportView: function ($event) {
                _vm.eventOrderExportModal = false
              },
            },
            model: {
              value: _vm.eventOrderExportModal,
              callback: function ($$v) {
                _vm.eventOrderExportModal = $$v
              },
              expression: "eventOrderExportModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }