<template>
    <v-container class="pa-0 ma-0" fluid>
      <v-toolbar id="products-toolbar" style="z-index: 10" color="fill">
        <v-row dense align="center">
          <v-col lg="3" style="max-width: 430px" class="text-truncate">
            <v-autocomplete
              v-model="storeId"
              outlined
              dense
              :items="stores"
              :loading="loading"
              item-text="name"
              item-value="id"
              hide-details
              background-color="white"
              label="Store"
              @change="getEvents"
              :clearable="isAdmin"
              @click:clear="getProducts"
              prepend-inner-icon="mdi-store-outline">
            </v-autocomplete>
          </v-col>
          <v-col lg="4" style="max-width: 430px">
            <v-autocomplete
              v-model="eventId"
              outlined
              dense
              :items="events"
              :loading="loading"
              item-text="name"
              item-value="id"
              hide-details
              background-color="white"
              label="Event"
              @change="handleChangedEvent"
              prepend-inner-icon="mdi-calendar">
              <template v-slot:append>
                <v-chip class="d-sm-none d-md-flex" small v-if="selectedEvent && !openStockSelected">
                  Ends {{ $config.formatDate(selectedEvent.order_end_date) }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-btn rounded class="text-capitalize" text color="main" @click="changeSort">
            <v-icon left>
              {{
                sortDirection == 'asc'
                ? 'mdi-sort-numeric-ascending'
                : sortDirection == 'desc'
                  ? 'mdi-sort-numeric-descending'
                  : 'mdi-sort'
              }}
            </v-icon>
            Sort
            <span class="ml-1 text-uppercase" v-show="sortDirection">
              ({{ sortDirection }})
            </span>
          </v-btn> -->
        <ProductFilter
          v-model="searchParams"
          :loading="loading"
          :productGroups="productGroups"
          :openStockSelected="openStockSelected"
          @filterInput="handleFilterInput">
        </ProductFilter>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                to="/orders"
                v-bind="attrs"
                v-on="on"
                small
                fab
                color="main"
                class="ml-4"
                rounded
                text
                >
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>
            <span>View product order history.</span>
          </v-tooltip>
          <v-divider vertical class="mx-4" inset />
          <ProductCart
            v-model="cartOpen"
            :store="selectedStore"
            :productEvent="selectedEvent"
            :loading="loading || loadingProductOrder"
            :order="order"
            :orderItems="productOrderItems"
            :orderingDisabled="orderingDisabled"
            :activeOrder="activeOrder"
            @orderItemUpdate="updateOrderItem"
            @removeItem="removeItem"
            @orderSubmitted="orderSubmitted"
            @orderCleared="orderCleared">
          </ProductCart>
        </v-row>
      </v-toolbar>
      <template v-if="loading">
        <div style="position: relative">
          <v-overlay absolute style="top: 300px" :value="loading" opacity="0.5" transition="scale-transition" z-index="999">
            <v-progress-circular indeterminate size="200" color="primary" height="6">
              <span class="primary--text text-subtitle-1">
                Loading Products...
              </span>
            </v-progress-circular>
          </v-overlay>
        </div>
      </template>
      <template v-else>
        <div ref="grid">
        <div id="grid-container" v-resize="onResize" class="pt-2 pb-4 px-2" style="overflow: auto" :style="{ height: gridHeight + 'px' }">
          <v-row v-if="products.length > 0" dense no-gutters>
            <v-col
              cols="12" xl="2" lg="2" md="4" sm="4" xs="6"
              v-for="(product, idx) in products"
              :key="idx">
              <ProductCard
                :loading="loading"
                :product="product"
                :orderingDisabled="orderingDisabled"
                @add="addToCart">
              </ProductCard>
            </v-col>
          </v-row>
          <v-row v-else dense class="my-4" >
            <v-col align="center">
              <p class="font-weight-light text-no-wrap">
                {{ openStockSelected ? 'No open stock products available' : 'No products to show' }}
              </p>
            </v-col>
          </v-row>
        </div>
        </div>
      </template>
      <PagingFooter
      v-if="!loading"
      style="position: relative;"
      :pageSizes="pageSizes"
      :pageSize.sync="pageSize"
      :page="page"
      :disablePrevious="disablePrevious"
      :disableNext="disableNext"
      @previous="previous"
      @next="next">
    </PagingFooter>
    </v-container>
</template>
<script>
// api
import ProductOrder from '@/api/product-order'
import Products from '@/api/product'
import ProductEvent from '@/api/product-event'
import ProductGroup from '@/api/product-group'
// mixins
import { userAccess } from '@/mixins/user-access'
import { partyHelpers } from '@/mixins/party-helpers'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
// components
import ProductCard from '@/components/products/ProductCard'
import ProductCart from '@/components/products/ProductCart'
import ProductFilter from '@/components/products/ProductFilter'
import PagingFooter from '@/components/PagingFooter'
export default {
  data() {
    return {
      order: null,
      eventId: null,
      currentEvents: [],
      openStockEvent: {
        id: 'open-stock', name: 'Open Stock'
      },
      products: [],
      productOrderItems: [],
      productsInOrder: [],
      productGroups: [],
      productGroupId: null,
      cartOpen: false,
      loading: true,
      loadingProductOrder: false,
      storeId: '',
      departments: [
        'Grocery - Dry',
        'Grocery - Dairy',
        'Health Beauty Care',
        'NonFood - General Merch',
        'Specialty Foods',
        'Grocery - Frozen',
        'Meat - Processed'
      ],
      isIntersecting: false,
      page: 1,
      pageSize: 48,
      pageSizes: [24, 48, 96],
      searchParams: {},
      itemId: '',
      gridHeight: '',
      cartLimit: 500,   // This value needs to be <= the default limit in src/api/product-order getProductOrderItems function
      // sortDirection: null
    }
  },
  name: 'ProductsView',
  mixins: [userAccess, partyHelpers, displayAlert, utils],
  components: {
    ProductFilter,
    ProductCard,
    ProductCart,
    PagingFooter
  },
  watch: {
    page: {
      handler(newValue) {
        if (newValue) {
          this.getProducts()
          const grid = this.$refs['grid']
          if (grid) {
            const container = grid.querySelector('#grid-container')

            this.$vuetify.goTo(grid)
            this.$vuetify.goTo(grid, { container })
          }
        }
      }
    },
     pageSize: {
      handler(newValue) {
        if (newValue) {
          if (this.page > 1) {
            return this.page = 1
          } else {
            this.getProducts()
          }
        }
      }
    },
    storeId(newValue) {
      if (newValue) {
        sessionStorage.setItem('store_id', newValue)
      } else {
        sessionStorage.removeItem('store_id')
      }
    },
    eventId(newValue) {
      if (newValue) {
        sessionStorage.setItem('event_id', newValue)
      } else {
        sessionStorage.removeItem('event_id')
      }
    }
  },
  async mounted() {
    this.loading = true
    await Promise.all([
        this.$store.dispatch('getUserRelatedParties'),
        this.$store.dispatch('getProductOrderTypes'),
        this.$store.dispatch('getStores')
    ]).catch(err => {
      this.loading = false
      this.handleError(err)
    })
    if (this.stores.length > 0) {
      if (sessionStorage.search_params) {
        this.searchParams = JSON.parse(sessionStorage.search_params)
      }
      if (sessionStorage.store_id) {
        this.storeId = sessionStorage.store_id
      } else {
        this.storeId = this.stores[0].id
      }
      await this.getEvents()
    } else {
      this.loading = false
    }
  },
  computed: {
    activeSearch () {
      if (sessionStorage.search_params) {
        const stored = JSON.parse(sessionStorage.search_params)
        return Object.keys(stored).some(key => Boolean(stored[key]))
      }
      return false
    },
    activeOrder () {
      return Boolean(this.order?.id)
    },
    sessionSearchParams() {
      if(sessionStorage.search_params) {
        return JSON.parse(sessionStorage.search_params)
      } else {
        return {}
      }
    },
    productOrderTypes() {
      return this.$store.state.productOrderTypes
    },
    openStockId() {
      if (this.productOrderTypes && this.productOrderTypes.length) {
        return this.productOrderTypes.find(kv => kv.name === "Open Stock")?.id
      } else {
        return null
      }
    },
    seasonalId() {
      if (this.productOrderTypes && this.productOrderTypes.length) {
        return this.productOrderTypes.find(kv => kv.name === "Seasonal")?.id
      } else {
        return null
      }
    },
    stores() {
      if (this.userStores.length > 0) {
        return this.sortByKey(this.userStores, 'name')
      }
      return []
    },
    selectedStore() {
      return this.stores.find(s => s?.id === this.storeId)
    },
    storeSource() {
      return this.eventId && !this.openStockSelected
        ? (this.selectedStore?.dc_source || null)
        : (this.selectedStore?.dsd_source || null)
    },
    storePriceKey() {
      return this.selectedStore?.price_key || null
    },
    events() {
      return [...this.currentEvents, this.openStockEvent]
    },
    selectedEvent() {
      return this.events.find(e => e?.id === this.eventId)
    },
    openStockSelected() {
      return this.eventId === 'open-stock'
    },
    orderingDisabled () {
      return !this.storeId
    },
    limit() {
      return this.pageSize
    },
    offset() {
      return (this.page - 1) * this.pageSize
    },
    isFiltered() {
      return Object.entries(this.searchParams).length > 0
    },
    disablePrevious() {
      return this.page === 1
    },
    disableNext() {
      return this.products.length < this.pageSize
    }
  },
  methods: {
    handleChangedEvent() {
      this.searchParams = {}
      if (sessionStorage.search_params) {
        sessionStorage.removeItem('search_params')
      }
      if (this.selectedEvent && !this.openStockSelected) {
        this.getProductGroups()
      } else {
        this.productGroups = []
      }
      return (this.page > 1) ? this.page = 1 : this.getProducts()
    },
    async getEvents() {
      const today = new Date().toISOString().substr(0, 10)
      let events = []
      try {
        const { data } = await ProductEvent.getList(0, 50, { 
          event_date: today,
          source: this.selectedStore?.dc_source
        })
        if (data?.length > 0) {
          events = this.sortByKey(data, 'name')
        }
        this.currentEvents = events
        if (this.events.length > 0) {
          if (sessionStorage.event_id) {
            this.eventId = sessionStorage.event_id
          }
          if (!this.selectedEvent && this.storeId) {
            this.eventId = this.events[0].id
          }
          this.getProductGroups()
        }
        await this.getProducts()
      } catch (err) {
        this.handleError(err)
        this.loading = false
      }
    },
    async getProductGroups () {
      if (!this.eventId || this.openStockSelected) return
      let groups = []
      try {
        const { data } = await ProductGroup.getEventGroups(this.eventId)
        if (data?.length > 0) {
          groups = this.sortByKey(data, 'name')
        }
        this.productGroups = groups
      } catch (err) {
        this.handleError(err)
      }
    },
    resetOrder () {
      this.order = null
      this.productOrderItems = []
    },
    async getProducts() {
      if (this.isRetailer && (!this.storeId)) return
      this.loading = true
      let products = []
      try {
        const params = {
          offset: this.offset, 
          limit: this.limit, 
          source: this.storeSource, 
          product_event_id: this.openStockSelected ? null : this.eventId,
          seasonal: (this.eventId && !this.openStockSelected),
          available: true,
          ...this.searchParams
        }
        if (!this.storeId || (this.storeId && this.storeSource)) {
          const { data } = await Products.getProducts(params)
          products = data.map(product => this.setProductPrices(product, this.storePriceKey))
        }
        this.products = products
        if (this.storeId && this.storeSource) {
          await this.getProductOrder()
        } else {
          this.resetOrder()
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async getProductOrder() {
      this.loadingProductOrder = true
      const params = {}
      if (this.eventId && !this.openStockSelected) {
        params.product_event_id = this.eventId
      }
      if (this.openStockSelected) {
        params.product_order_type_id = this.openStockId
      } else if (this.selectedEvent) {
        params.product_order_type_id = this.seasonalId
      }
      try {
        const { data } = await ProductOrder.getProductOrder(this.storeId, params)
        if (data) {
          this.order = data
          this.getProductOrderItems()
        } else {
          this.resetOrder()
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loadingProductOrder = false
      }
    },
    async getProductOrderItems() {
      try {
        const orderType = this.productOrderTypes.find(kv => kv.id === this.order.product_order_type_id).constant
        const params = { product_order_type: orderType, offset: 0, limit: this.cartLimit }
        const { data } = await ProductOrder.getProductOrderItems(params, this.order.id)
        const sorted = data.sort((a, b) => Date.parse(a.audit.created_on) - Date.parse(b.audit.created_on))
        this.productOrderItems = sorted.map(product => this.setProductPrices(product, this.storePriceKey))
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingProductOrder = false
      }
    },
    async createProductOrder() {
      const payload = {
        party_id: this.storeId,
        product_event_id: this.openStockSelected ? null : this.eventId,
        product_order_type_id: this.openStockSelected ? this.openStockId : this.seasonalId,
        submitted: false
      }
      return ProductOrder.createProductOrder(payload)
    },
    async addToCart({ product, quantity = 1 }) {
      this.loadingProductOrder = true
      const existingOrderItem = this.productOrderItems.find(item => item.product_id === product.id)
      if (existingOrderItem) {
        const currentQty = existingOrderItem.quantity
        existingOrderItem.quantity = (Number(currentQty) + Number(quantity))
        return this.updateOrderItem(existingOrderItem)
      }
      // Make sure excess items don't get added - prevents clear cart issue
      if (this.productOrderItems.length >= this.cartLimit)
      {
        return this.emitAlert(true, 'warning', `Cart has reached unique item limit.`, [], true)
      }
      let orderId = this.order?.id
      try {
        if (!orderId) {
          const { data } = await this.createProductOrder()
          orderId = data?.uuid
        }
        const productOrderItem = { 
          product_order_id: orderId, 
          source: product.source, 
          item_id: product.item_id, 
          upc: product.upc, 
          quantity
        }
        await ProductOrder.createProductOrderItem(productOrderItem)
        return this.order?.id
          ? this.getProductOrderItems()
          : this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loadingProductOrder = false
      }
    },
    async updateOrderItem(productOrderItem) {
      this.loadingProductOrder = true
      try {
        if(productOrderItem.quantity === 0) {
          await ProductOrder.deleteProductOrderItem(productOrderItem.id)
        } else {
          await ProductOrder.updateProductOrderItem(productOrderItem.id, productOrderItem)
        }
        await this.getProductOrderItems()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingProductOrder = false
      }
    },
    removeItem(productOrderItem) {
      productOrderItem.quantity = 0
      this.updateOrderItem(productOrderItem)
    },
    orderSubmitted() {
      this.emitAlert(true, 'success', `Product order has been submitted.`, [], true)
      this.getProductOrder()
    },
    orderCleared() {
      this.emitAlert(true, 'success', `Product order items have been removed.`, [], true)
      this.resetOrder()
    },
    onResize() {
      this.gridHeight = window.innerHeight - 219
    },
    handleFilterInput(newValue) {
      this.searchParams = newValue
      return (this.page > 1) ? this.page = 1 : this.getProducts()
    },
    // changeSort() {
    //   switch (this.sortDirection) {
    //     case null:
    //       return (this.sortDirection = 'asc')
    //     case 'asc':
    //       return (this.sortDirection = 'desc')
    //     case 'desc':
    //       return (this.sortDirection = null)
    //     default:
    //       return null
    //   }
    // },
    showCart() {
      this.cartOpen = true
    },
    closeCart() {
      this.cartOpen = false
    },
    next() {
      this.page++
    },
    previous() {
      this.page--
    }
  }
}
</script>
<style>
.loader {
  margin: auto;
  display: flex;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--v-primary-base);
  margin: 0 5px;
  animation: loader-animation 0.8s infinite;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0.7;
  }
}
</style>
