var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { tile: "", flat: "", color: "grey lighten-3" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "ml-4 pt-4",
              staticStyle: { cursor: "default" },
              attrs: { flat: "", color: "grey lighten-3" },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-clipboard-text"),
              ]),
              _c("v-toolbar-title", { staticClass: "title" }, [
                _vm._v(" Order Entry "),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "text-truncate ml-4",
                  staticStyle: { "max-width": "430px" },
                  attrs: { lg: "4" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Store",
                      items: _vm.stores,
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      dense: "",
                      required: "",
                      rules: [(v) => !!v || "Store is required"],
                      "background-color": "input",
                      "hide-details": "auto",
                      "prepend-inner-icon": "mdi-store-outline",
                    },
                    on: {
                      "update:items": function ($event) {
                        _vm.stores = $event
                      },
                    },
                    model: {
                      value: _vm.storeId,
                      callback: function ($$v) {
                        _vm.storeId = $$v
                      },
                      expression: "storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-none d-sm-flex text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { lg: "4" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Customer Invoice Note (Optional)",
                      dense: "",
                      "validate-on-blur": "",
                      "background-color": "input",
                      outlined: "",
                      maxlength: "14",
                      "hide-details": "auto",
                    },
                    on: {
                      keyup: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleNoteInput(_vm.note)
                      },
                    },
                    model: {
                      value: _vm.note,
                      callback: function ($$v) {
                        _vm.note = $$v
                      },
                      expression: "note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "px-4 mb-4", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex d-sm-none px-4 mt-2",
                  attrs: { dense: "", align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-truncate" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Customer Invoice Note (Optional)",
                          dense: "",
                          "validate-on-blur": "",
                          "background-color": "input",
                          outlined: "",
                          maxlength: "14",
                          "hide-details": "auto",
                        },
                        on: {
                          keyup: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleNoteInput(_vm.note)
                          },
                        },
                        model: {
                          value: _vm.note,
                          callback: function ($$v) {
                            _vm.note = $$v
                          },
                          expression: "note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "px-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", sm: "3", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Item ID",
                          disabled: _vm.loading,
                          dense: "",
                          required: "",
                          rules: [(v) => !!v || "Item ID is required"],
                          "background-color": "input",
                          "validate-on-blur": "",
                          outlined: "",
                          "hide-details": "auto",
                        },
                        model: {
                          value: _vm.itemId,
                          callback: function ($$v) {
                            _vm.itemId = $$v
                          },
                          expression: "itemId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", sm: "2", md: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Qty",
                          type: "number",
                          min: "1",
                          "background-color": "input",
                          outlined: "",
                          dense: "",
                          "hide-details": "auto",
                          disabled: _vm.loading,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.qtyChange()
                          },
                        },
                        model: {
                          value: _vm.item.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "quantity", $$v)
                          },
                          expression: "item.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "margin-top": "2px" },
                          attrs: {
                            color: "primary",
                            disabled: _vm.disableLookup || _vm.loading,
                          },
                          on: { click: _vm.lookupItem },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-magnify"),
                          ]),
                          _vm._v(" Lookup "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "margin-top": "2px" },
                          attrs: {
                            color: "success",
                            disabled:
                              _vm.disableLookup ||
                              !_vm.item.uuid ||
                              _vm.existingItem ||
                              _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addItem(_vm.item)
                            },
                          },
                        },
                        [_vm._v(" Add Item ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "px-4 mt-4 d-none d-sm-flex",
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", md: "3", sm: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Description",
                          disabled: "",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.item.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "UPC",
                          disabled: "",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.item.upc,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "upc", $$v)
                          },
                          expression: "item.upc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", md: "1", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Pack",
                          disabled: "",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.item.qty_per_case,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "qty_per_case", $$v)
                          },
                          expression: "item.qty_per_case",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", md: "1", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Size",
                          disabled: "",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.item.pack_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "pack_size", $$v)
                          },
                          expression: "item.pack_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", md: "1", sm: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Cost",
                          disabled: "",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.itemCost,
                          callback: function ($$v) {
                            _vm.itemCost = $$v
                          },
                          expression: "itemCost",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            {
              staticStyle: {
                "border-top": "1px solid #ddd",
                "background-color": "#fff",
              },
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-2 d-none d-sm-flex" },
                    [_c("v-toolbar-title", [_vm._v("Order Items")])],
                    1
                  ),
                  _c("v-spacer", { staticClass: "d-none d-sm-flex" }),
                  _c(
                    "v-col",
                    { staticClass: "pr-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-medium text-no-wrap text-h6 mb-0",
                        },
                        [_vm._v(" Total: $" + _vm._s(_vm.totalCost) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            loading: _vm.loading,
                            disabled:
                              _vm.orderItems.length === 0 || _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              _vm.confirmSubmit = true
                            },
                          },
                        },
                        [_vm._v(" Submit Order ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text ml-2",
                          attrs: {
                            disabled: !_vm.order.id || _vm.loading,
                            fab: "",
                            "x-small": "",
                            elevation: "2",
                            color: "error",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.confirmDelete = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c("OrderItemsTable", {
            attrs: { items: _vm.orderItems },
            on: { updateItem: _vm.updateItem, removeItem: _vm.removeItem },
          }),
        ],
        1
      ),
      _vm.confirmSubmit
        ? _c("ConfirmDialog", {
            attrs: {
              title: "Submit Item Order",
              text: 'Change order status to "submitted"? Changes may no longer be made to the item order after submission.',
            },
            on: { cancel: _vm.cancelSubmit, confirm: _vm.submitOrder },
            model: {
              value: _vm.confirmSubmit,
              callback: function ($$v) {
                _vm.confirmSubmit = $$v
              },
              expression: "confirmSubmit",
            },
          })
        : _vm._e(),
      _vm.confirmDelete
        ? _c("ConfirmDialog", {
            attrs: {
              title: "Clear Item Order",
              text: "Clear the current order? This will clear all items.",
            },
            on: { cancel: _vm.cancelDelete, confirm: _vm.clearOrder },
            model: {
              value: _vm.confirmDelete,
              callback: function ($$v) {
                _vm.confirmDelete = $$v
              },
              expression: "confirmDelete",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }