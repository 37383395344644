var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", width: "700" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "my-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "11", align: "start" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "my-0 py-0 pt-0 heading-6" },
                        [
                          _c("div", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              " Product Order: " +
                                _vm._s(
                                  _vm.selectedProductOrder.product_order_number
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _vm.selectedProductOrder.product_event_name
                        ? _c(
                            "v-card-subtitle",
                            { staticClass: "my-0 py-0 pt-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "Event: " +
                                      _vm.selectedProductOrder
                                        .product_event_name
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0 pr-4", attrs: { align: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("mdi-close"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.loading
                ? [
                    _c("v-progress-linear", {
                      attrs: {
                        indeterminate: "",
                        color: "primary",
                        height: "6",
                      },
                    }),
                  ]
                : [
                    _c(
                      "v-row",
                      {
                        staticClass: "header mx-1 py-1",
                        attrs: { dense: "", align: "center" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-2 ml-0",
                            attrs: { align: "start" },
                          },
                          [_vm._v(" Item ")]
                        ),
                        _c("v-col", { attrs: { cols: "2" } }, [
                          _vm._v(" Cost "),
                        ]),
                        _c(
                          "v-col",
                          { staticClass: "pl-0 mr-2", attrs: { cols: "auto" } },
                          [_vm._v(" Qty ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mx-1 pb-1", attrs: { dense: "" } },
                      [
                        _vm.orderItems.length > 0
                          ? _c(
                              "v-list",
                              {
                                staticClass: "pt-0",
                                staticStyle: {
                                  "max-height": "60vh",
                                  "overflow-y": "auto",
                                },
                                attrs: { dense: "", width: "100%" },
                              },
                              [
                                _vm._l(_vm.orderItems, function (item, index) {
                                  return [
                                    _c(
                                      "div",
                                      { key: item.item_id },
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "ma-0 pl-2 py-1" },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "pl-0 ml-0",
                                                attrs: {
                                                  dense: "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pl-0 ml-0",
                                                    attrs: { align: "start" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " #" +
                                                        _vm._s(
                                                          item.item_id + " - "
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          item.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "2" } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.cost
                                                            ? "$" +
                                                                _vm.$config.formatCurrency(
                                                                  item.cost
                                                                )
                                                            : "-"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pl-0 mr-2",
                                                    attrs: { cols: "auto" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.quantity) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        index < _vm.orderItems.length - 1
                                          ? _c("v-divider")
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _c(
                              "v-row",
                              { staticClass: "my-4", attrs: { dense: "" } },
                              [
                                _c("v-col", { attrs: { align: "center" } }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-light text-no-wrap",
                                    },
                                    [_vm._v(" This product order is empty. ")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "py-2",
                            attrs: { dense: "", align: "center" },
                          },
                          [
                            _c("v-col", { attrs: { align: "center" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-light text-no-wrap my-auto",
                                  staticStyle: { "font-size": "14px" },
                                },
                                [
                                  _vm._v(
                                    " Updated by " +
                                      _vm._s(
                                        _vm.selectedProductOrder.audit
                                          .updated_by
                                      ) +
                                      " on " +
                                      _vm._s(
                                        _vm.$config.formatTimestamp(
                                          _vm.selectedProductOrder.audit
                                            .updated_on
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("v-col", { attrs: { align: "end" } }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-medium text-no-wrap my-auto mr-16",
                                },
                                [
                                  _vm._v(
                                    " Total: $" + _vm._s(_vm.totalCost) + " "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }