<template>
  <v-card flat class="pb-0 mb-0 pt-0" min-width='100%'>
    <div class="dataCon">
      <v-form class="tableCon">
        <v-data-table id="itemMaster" :height="tableSize" :headers="computedHeaders" :items="items"
          :loading="loading" item-key="uuid" hide-default-footer :items-per-page.sync="pageSize"
          no-data-text="No items" fixed-header dense>
          <template v-slot:[`item.sale_price`]="{ item }">
            <td class="text-no-wrap">
              {{ item.sale_price == 0 ? '-' : `${item.sale_price ? '$' + item.sale_price.toFixed(2) : '-'}` }}
            </td>
          </template>
          <template v-slot:[`item.cost`]="{ item }">
            <td class="text-no-wrap">{{ item.cost ? '$' + item.cost.toFixed(2) : '-' }}</td>
          </template>
          <template v-slot:[`item.remove`]="{ item }">
            <v-btn icon color="error" @click.stop="removeItem(item)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <template #[`item.quantity`]="{ item }">
            <v-menu
              v-model="editingQuantity"
              :close-on-content-click="false"
              :close-on-click="false"
              bottom
              right
              absolute
              nudge-top="-12px"
              nudge-left="16px"
              offset-y
              max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div class="edit-menu-container ml-0 pl-0"
                  v-on="on"
                  v-bind="attrs"
                  @click="open(item)">
                  <v-btn class="mx-0 pa-0" depressed small>
                    {{ item.quantity }}
                  </v-btn>
                </div>
              </template>
              <v-card
                v-if="editingQuantity && editItem && editItem.id === item.id"
                v-click-outside="onClickOutsideMenu"
                class="pt-2 px-4"
                style="z-index: 97"
                tile
                flat>
                <div style="margin-top: 8px">
                  <span class="subtitle-2">Edit Quantity</span>
                </div>
                  <div class="qty-controls ml-1">
                    <v-btn 
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      :disabled="editItem.quantity === 1"
                      @click="decreaseQty">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-text-field
                      ref="quantity"
                      v-model="editItem.quantity"
                      class="pt-2 mt-0"
                      :rules="[
                        v => !!v || 'Count Required',
                        v => !(v < 0) || 'Cannot have negative quantity'
                      ]"
                      placeholder="Qty"
                      min="1"
                      single-line
                      autofocus
                      hide-spin-buttons
                      type="number">
                    </v-text-field>
                    <v-btn
                      icon 
                      x-small
                      class="my-auto py-0 my-0"
                      fab
                      dense
                      color="primary"
                      @click="increaseQty">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                <v-card-actions class="pt-0 px-0">
                  <v-spacer/>
                  <v-btn
                    text
                    small
                    @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="!editItem.quantity || editItem.quantity < 0"
                    @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <template v-slot:footer>
            <PagingFooter :pageSizes="pageSizes" :pageSize.sync="pageSize" :page="page"
              :disablePrevious="disablePrevious" :disableNext="disableNext" @previous="previous" @next="next">
            </PagingFooter>
          </template>
        </v-data-table>
      </v-form>
    </div>
  </v-card>
</template>
<script>
// Components
import PagingFooter from '@/components/PagingFooter.vue'
// Mixins
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
import { fullWidthTable } from '@/mixins/table'
import { cloneDeep } from 'lodash'
export default {
  name: 'OrderItemsTable',
  data() {
    return {
      from: 0,
      pageSize: 50,
      headers: [
        { text: 'Item ID', value: 'item_id', show: true },
        { text: 'Description', value: 'description', show: true },
        { text: 'UPC', value: 'upc', show: true },
        { text: 'Cost', value: 'cost', show: true },
        { text: 'Qty', value: 'quantity', show: true },
        { text: '', value: 'remove', show: true, sortable: false, align: 'center' }
      ],
      editItem: {},
      editingQuantity: false,
      loading: false
    }
  },
  props: ['items'],
  mixins: [ fullWidthTable, userAccess, displayAlert ],
  components: { PagingFooter },
  mounted() {
    this.nonTableHeight = 452
  },
  computed: {
    disablePrevious() {
      return this.from === 0
    },
    disableNext() {
      return this.items.length < this.pageSize
    },
    computedHeaders() {
      return this.headers.flatMap(item => {
        return { ...item, class: 'accent white--text text-no-wrap' }
      })
    }
  },
  methods: {
    open (item) {
      this.editItem = cloneDeep(item)
    },
    save () {
      this.editingQuantity = false
      this.$emit('updateItem', this.editItem)
    },
    cancel () {
      this.editItem = null
      this.editingQuantity = false
    },
    increaseQty() {
      this.editItem.quantity = parseInt(this.editItem.quantity) + 1
    },
    decreaseQty() {
      this.editItem.quantity = parseInt(this.editItem.quantity) - 1
    },
    removeItem(item) {
      this.$emit('removeItem', item)
    },
    onClickOutsideMenu () {
      this.editingQuantity = false
    },
  }
}
</script>
<style lang="scss" scoped>
.active {
  box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
  background: #385F73;
  color: white !important;
}
.edit-menu-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-column-gap: 0px;
  position: relative;
}
.qty-controls {
  display: grid; 
  width: 100%; 
  max-width: 100px; 
  grid-template-columns: auto 50px auto; 
  justify-content: flex-center;
}
.theme--light.v-data-table .v-data-table-header th.sortable.accent.white--text .v-data-table-header__icon {
  color: #fff !important;
}
</style>