var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticStyle: { "z-index": "10" },
          attrs: { id: "products-toolbar", color: "fill" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { lg: "3" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      items: _vm.stores,
                      loading: _vm.loading,
                      "item-text": "name",
                      "item-value": "id",
                      "hide-details": "",
                      "background-color": "white",
                      label: "Store",
                      clearable: _vm.isAdmin,
                      "prepend-inner-icon": "mdi-store-outline",
                    },
                    on: {
                      change: _vm.getEvents,
                      "click:clear": _vm.getProducts,
                    },
                    model: {
                      value: _vm.storeId,
                      callback: function ($$v) {
                        _vm.storeId = $$v
                      },
                      expression: "storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "max-width": "430px" }, attrs: { lg: "4" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      items: _vm.events,
                      loading: _vm.loading,
                      "item-text": "name",
                      "item-value": "id",
                      "hide-details": "",
                      "background-color": "white",
                      label: "Event",
                      "prepend-inner-icon": "mdi-calendar",
                    },
                    on: { change: _vm.handleChangedEvent },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _vm.selectedEvent && !_vm.openStockSelected
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "d-sm-none d-md-flex",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _vm._v(
                                      " Ends " +
                                        _vm._s(
                                          _vm.$config.formatDate(
                                            _vm.selectedEvent.order_end_date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.eventId,
                      callback: function ($$v) {
                        _vm.eventId = $$v
                      },
                      expression: "eventId",
                    },
                  }),
                ],
                1
              ),
              _c("ProductFilter", {
                attrs: {
                  loading: _vm.loading,
                  productGroups: _vm.productGroups,
                  openStockSelected: _vm.openStockSelected,
                },
                on: { filterInput: _vm.handleFilterInput },
                model: {
                  value: _vm.searchParams,
                  callback: function ($$v) {
                    _vm.searchParams = $$v
                  },
                  expression: "searchParams",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    to: "/orders",
                                    small: "",
                                    fab: "",
                                    color: "main",
                                    rounded: "",
                                    text: "",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-history")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("View product order history.")])]
              ),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { vertical: "", inset: "" },
              }),
              _c("ProductCart", {
                attrs: {
                  store: _vm.selectedStore,
                  productEvent: _vm.selectedEvent,
                  loading: _vm.loading || _vm.loadingProductOrder,
                  order: _vm.order,
                  orderItems: _vm.productOrderItems,
                  orderingDisabled: _vm.orderingDisabled,
                  activeOrder: _vm.activeOrder,
                },
                on: {
                  orderItemUpdate: _vm.updateOrderItem,
                  removeItem: _vm.removeItem,
                  orderSubmitted: _vm.orderSubmitted,
                  orderCleared: _vm.orderCleared,
                },
                model: {
                  value: _vm.cartOpen,
                  callback: function ($$v) {
                    _vm.cartOpen = $$v
                  },
                  expression: "cartOpen",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? [
            _c(
              "div",
              { staticStyle: { position: "relative" } },
              [
                _c(
                  "v-overlay",
                  {
                    staticStyle: { top: "300px" },
                    attrs: {
                      absolute: "",
                      value: _vm.loading,
                      opacity: "0.5",
                      transition: "scale-transition",
                      "z-index": "999",
                    },
                  },
                  [
                    _c(
                      "v-progress-circular",
                      {
                        attrs: {
                          indeterminate: "",
                          size: "200",
                          color: "primary",
                          height: "6",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "primary--text text-subtitle-1" },
                          [_vm._v(" Loading Products... ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c("div", { ref: "grid" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "resize",
                      rawName: "v-resize",
                      value: _vm.onResize,
                      expression: "onResize",
                    },
                  ],
                  staticClass: "pt-2 pb-4 px-2",
                  staticStyle: { overflow: "auto" },
                  style: { height: _vm.gridHeight + "px" },
                  attrs: { id: "grid-container" },
                },
                [
                  _vm.products.length > 0
                    ? _c(
                        "v-row",
                        { attrs: { dense: "", "no-gutters": "" } },
                        _vm._l(_vm.products, function (product, idx) {
                          return _c(
                            "v-col",
                            {
                              key: idx,
                              attrs: {
                                cols: "12",
                                xl: "2",
                                lg: "2",
                                md: "4",
                                sm: "4",
                                xs: "6",
                              },
                            },
                            [
                              _c("ProductCard", {
                                attrs: {
                                  loading: _vm.loading,
                                  product: product,
                                  orderingDisabled: _vm.orderingDisabled,
                                },
                                on: { add: _vm.addToCart },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-row",
                        { staticClass: "my-4", attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { align: "center" } }, [
                            _c(
                              "p",
                              { staticClass: "font-weight-light text-no-wrap" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.openStockSelected
                                        ? "No open stock products available"
                                        : "No products to show"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ],
      !_vm.loading
        ? _c("PagingFooter", {
            staticStyle: { position: "relative" },
            attrs: {
              pageSizes: _vm.pageSizes,
              pageSize: _vm.pageSize,
              page: _vm.page,
              disablePrevious: _vm.disablePrevious,
              disableNext: _vm.disableNext,
            },
            on: {
              "update:pageSize": function ($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function ($event) {
                _vm.pageSize = $event
              },
              previous: _vm.previous,
              next: _vm.next,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }