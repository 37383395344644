var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "nudge-bottom": 5,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-auto text-capitalize",
                    attrs: { rounded: "", text: "", color: "main" },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-filter-outline"),
                  ]),
                  _vm._v(" Filters "),
                  _c("v-badge", {
                    staticClass: "ml-2 mb-2",
                    attrs: {
                      color: "accent",
                      content: _vm.filterCount,
                      value: _vm.filterCount > 0,
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.filterMenu,
        callback: function ($$v) {
          _vm.filterMenu = $$v
        },
        expression: "filterMenu",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { width: "350" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-4" },
              _vm._l(_vm.searchFields, function (field, idx) {
                return _c(
                  "v-row",
                  { key: idx, attrs: { dense: "", justify: "center" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "ma-0 pa-0",
                        attrs: { cols: field.type === "boolean" ? 6 : null },
                      },
                      [
                        field.type === "boolean"
                          ? _c("v-switch", {
                              staticClass: "my-3",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                label: field.name,
                              },
                              on: { click: _vm.updateSearchFilters },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            })
                          : field.type === "date_range"
                          ? _c("v-text-field", {
                              staticClass: "ma-0",
                              staticStyle: { padding: "5px" },
                              attrs: {
                                "background-color": "white",
                                clearable: "",
                                dense: "",
                                "hide-details": "",
                                label: field.name,
                                outlined: "",
                                readonly: "",
                                "prepend-inner-icon": "mdi-calendar",
                                value: _vm.formatSubmittedDateRange(
                                  _vm.filters[`${field.key}`]
                                ),
                              },
                              on: {
                                "click:clear": function ($event) {
                                  return _vm.clearDateRangeFilter(field.key)
                                },
                                click: function ($event) {
                                  return _vm.toggleDateMenu(field.key)
                                },
                              },
                            })
                          : field.type === "text"
                          ? _c("v-text-field", {
                              staticClass: "ma-0",
                              staticStyle: { padding: "5px" },
                              attrs: {
                                label: field.name,
                                outlined: "",
                                "hide-details": "auto",
                                hint: "Press enter to search",
                                clearable: "",
                                loading:
                                  Boolean(_vm.filters[field.key]) &&
                                  _vm.loading,
                                color: "primary",
                                dense: "",
                                "prepend-inner-icon": "mdi-magnify",
                                "background-color": "white",
                              },
                              on: {
                                "click:clear": function ($event) {
                                  return _vm.clearFilter(field.key)
                                },
                                change: _vm.updateSearchFilters,
                              },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            })
                          : field.type === "order_types"
                          ? _c("v-autocomplete", {
                              staticClass: "ma-0",
                              staticStyle: { padding: "5px" },
                              attrs: {
                                items: _vm.productOrderTypes,
                                label: field.name,
                                "item-text": "name",
                                "item-value": "id",
                                outlined: "",
                                "hide-details": "",
                                clearable: "",
                                loading:
                                  Boolean(_vm.filters[field.key]) &&
                                  _vm.loading,
                                color: "primary",
                                dense: "",
                                "prepend-inner-icon": "mdi-magnify",
                                "background-color": "white",
                              },
                              on: {
                                "click:clear": function ($event) {
                                  return _vm.clearFilter(field.key)
                                },
                                change: _vm.updateSearchFilters,
                              },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    field.type === "date_range" && _vm.dateMenu
                      ? _c(
                          "v-card",
                          [
                            _c("v-date-picker", {
                              attrs: {
                                range: "",
                                "show-current": "",
                                "no-title": "",
                              },
                              model: {
                                value: _vm.filters[`${field.key}`],
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, `${field.key}`, $$v)
                                },
                                expression: "filters[`${field.key}`]",
                              },
                            }),
                            _c(
                              "v-card-actions",
                              {
                                staticClass: "ma-0 pa-0 mr-2",
                                staticStyle: {
                                  position: "absolute",
                                  right: "0",
                                  bottom: "0",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearDateRangeFilter(
                                          field.key,
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Clear ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChangedDate()
                                      },
                                    },
                                  },
                                  [_vm._v(" Confirm ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }