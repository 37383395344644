var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pb-0 mb-0 pt-0", attrs: { flat: "", "min-width": "100%" } },
    [
      _c(
        "div",
        { staticClass: "dataCon" },
        [
          _c(
            "v-form",
            { staticClass: "tableCon" },
            [
              _c("v-data-table", {
                attrs: {
                  id: "itemMaster",
                  height: _vm.tableSize,
                  headers: _vm.computedHeaders,
                  items: _vm.items,
                  loading: _vm.loading,
                  "item-key": "uuid",
                  "hide-default-footer": "",
                  "items-per-page": _vm.pageSize,
                  "no-data-text": "No items",
                  "fixed-header": "",
                  dense: "",
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:items-per-page": function ($event) {
                    _vm.pageSize = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.sale_price`,
                      fn: function ({ item }) {
                        return [
                          _c("td", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.sale_price == 0
                                    ? "-"
                                    : `${
                                        item.sale_price
                                          ? "$" + item.sale_price.toFixed(2)
                                          : "-"
                                      }`
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: `item.cost`,
                      fn: function ({ item }) {
                        return [
                          _c("td", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              _vm._s(
                                item.cost ? "$" + item.cost.toFixed(2) : "-"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: `item.remove`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", color: "error" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeItem(item)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-trash-can")])],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.quantity`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                right: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container ml-0 pl-0",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.open(item)
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mx-0 pa-0",
                                                attrs: {
                                                  depressed: "",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.quantity) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingQuantity,
                                callback: function ($$v) {
                                  _vm.editingQuantity = $$v
                                },
                                expression: "editingQuantity",
                              },
                            },
                            [
                              _vm.editingQuantity &&
                              _vm.editItem &&
                              _vm.editItem.id === item.id
                                ? _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "click-outside",
                                          rawName: "v-click-outside",
                                          value: _vm.onClickOutsideMenu,
                                          expression: "onClickOutsideMenu",
                                        },
                                      ],
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "97" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Quantity")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "qty-controls ml-1" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                                disabled:
                                                  _vm.editItem.quantity === 1,
                                              },
                                              on: { click: _vm.decreaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-minus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-text-field", {
                                            ref: "quantity",
                                            staticClass: "pt-2 mt-0",
                                            attrs: {
                                              rules: [
                                                (v) => !!v || "Count Required",
                                                (v) =>
                                                  !(v < 0) ||
                                                  "Cannot have negative quantity",
                                              ],
                                              placeholder: "Qty",
                                              min: "1",
                                              "single-line": "",
                                              autofocus: "",
                                              "hide-spin-buttons": "",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.editItem.quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editItem,
                                                  "quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "editItem.quantity",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.increaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.quantity ||
                                                  _vm.editItem.quantity < 0,
                                              },
                                              on: { click: _vm.save },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("PagingFooter", {
                            attrs: {
                              pageSizes: _vm.pageSizes,
                              pageSize: _vm.pageSize,
                              page: _vm.page,
                              disablePrevious: _vm.disablePrevious,
                              disableNext: _vm.disableNext,
                            },
                            on: {
                              "update:pageSize": function ($event) {
                                _vm.pageSize = $event
                              },
                              "update:page-size": function ($event) {
                                _vm.pageSize = $event
                              },
                              previous: _vm.previous,
                              next: _vm.next,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }